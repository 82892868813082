<template>
  <div class="activity-rule">
    <div class="content">
      <div>1.活动对象：2G/3G/4G/5G手机用户</div>
      <div>
        本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。
      </div>
      <div>2.活动内容：</div>
      <div>
        订购随心享会员，25元/月，每30天可领取热门会员权益*2＋5元话费券*1，到期前24小时内将自动扣费续订。例如张三在2023年3月15日10:56分订购了随心淘会员，那么在2023年3月15日10:56分~2023年4月13日10:55期间，张三可任选2款会员权益+5元话费券。如张一在2023年4月12日10:55前未退订服务，那么在2023年4月12日10:55~2023年4月13日10:55期间将自动扣费下一个30天计费周期的费用。
      </div>
      <div>
        3.生失效方式：
        订购立即生效，当前计费周期费用（30天)一次性扣除下一个计费周期自动续订，退订需在到期前24小时。
      </div>
      <div>4.权益领取使用及有效期：</div>
      <div>会员权益每个计费周期（30天）限领1次，逾期作废。</div>
      <div>
        领取路径：登录支付宝APP,进入支付宝小程序【秋末会员商城】,点击【随心享会员】，进入后选择需要的权益领取；
      </div>
      <div>（1）视听会员权益如腾讯爱奇艺登录对应平台即可使用；</div>
      <div>（2）5元话费券使用说明</div>
      <div>
        ①
        红包自用户领取起30天内有效，领取后发放到支付宝卡包，红包不可拆分，不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div>
        ②
        该红包可用场景为支付宝话费充值-话费常规充值/话费代扣充值(自动充),使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于5.01元时可抵扣使用(实际使用范围以红包可抵扣为准)。使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变；退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div>
        ③
        支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与；同一用户活动期内仅能参加1次。同一支付宝账号、手机号、身份证、手机终端，符合以上任一条件均视为同一用户。
      </div>
      <div>
        ④
        为确保活动公平性，如用户出现不正当行为(如虚假交易、作弊、恶意套取现金、刷信等),银行有权撤销用户优惠资格，并有权收回用户获得的福利。
      </div>
      <div>5.退订方式</div>
      <div>
        退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后3个月内不能再次订购；
      </div>
      <div>
        退订路径：打开支付宝-[我的]-[设置]-[支付设置]-[免密支付/自动扣款]，
        选择随心淘取消订阅微信[我的]-[服务]-[钱包]-[支付设置]-[自动续费]，选择随心淘取消订阅。
      </div>
      <div>6.如对活动有疑问或更多需求，请咨询在线客服或拨打4001189599</div>
      <div>【订购须知】</div>
      <div>
        1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉！
      </div>
      <div>
        2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，道防网络电信诈骗。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "随心会员";
  },
};
</script>

<style lang="scss" scoped>
.activity-rule {
  background: #fdebca
    url("https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/lnzx25_04.png")
    center top/contain no-repeat;
  padding: 184px 0 47px;
  box-sizing: border-box;
  min-height: 100%;

  .content {
    background-color: rgba(255, 249, 245, 0.9);
    border-radius: 6px;
    font-size: 24px;
    color: #4d2f1c;
    padding: 29px 27px 71px 21px;
    width: 680px;
    margin: 0 auto;
    box-sizing: border-box;

    div {
      font-weight: normal;
      line-height: 34px;
      font-stretch: normal;
      letter-spacing: 1px;
    }
  }
}
</style>
